import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import '../Styles/Schools/Grades.css';
import { db } from "./Firebase";

const Grades = () => {
    const { School_Name } = useParams();
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [newClassName, setNewClassName] = useState('');

    const fetchClasses = async () => {
        try {
            const snapshot = await db.collection('Schools').doc(School_Name).collection('Classes').get();
            const fetchedClasses = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setClasses(fetchedClasses);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching classes:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClasses();
    }, [School_Name]);

    const handleAddClass = async () => {
        if (newClassName.trim() !== '') {
            try {
                await db.collection('Schools').doc(School_Name).collection('Classes').add({ name: newClassName });
                setNewClassName('');
                setShowPopup(false); 
                fetchClasses();
            } catch (error) {
                console.error('Error adding class:', error);
            }
        }
    };

    return (
        <React.Fragment>

            <div className="GHead">
                <h1>כיתות / {School_Name}</h1>
            </div>
            <div className="Top-Bar">
                <button onClick={() => setShowPopup(true)}>להוסיף כיתה</button>
            </div>

            <div className="classes-list">
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <ul>
                        {classes.map(cls => (
                            <li key={cls.id}>{cls.name}</li>
                        ))}
                    </ul>
                )}
            </div>

            {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="close" onClick={() => setShowPopup(false)}>&times;</span>
                        <input
                            type="text"
                            value={newClassName}
                            onChange={e => setNewClassName(e.target.value)}
                            placeholder="שם הכיתה"
                        />
                        <button onClick={handleAddClass}>הוסף כיתה</button>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default Grades;
