import { Route, Routes } from "react-router-dom";
import Home from "./Components/Home";

import SchoolInfo from "./Components/School-Info";
import Teachers from "./Components/Teachers";
import Classes from "./Components/Classes";
import Login from "./Components/Login";


function App(){
  return(
    <Routes>
      <Route path="/" element={<Login/>}/>
      <Route path="/Home" element={<Home/>}/>
      <Route path="School/Info/:School_Name" element={<SchoolInfo/>}/>
      <Route path="School/Info/:School_Name/Teachers" element={<Teachers/>}/>
      <Route path="School/Info/:School_Name/Classes" element={<Classes/>}/>
    </Routes>
  )
}

export default App