import React, { useState, useEffect } from "react";

import '../Styles/Home/Home-Main.css'

import Logo from '../Images/Logo/MainLogo.png'

import Schools from "./Schools";

const Home = () => {

    const [dateTime, setDateTime] = useState(new Date());
    const [activeButton, setActiveButton] = useState('סקירה כללית');

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    }, []);

    function tick() {
        setDateTime(new Date());
    }

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };

    const renderMainInfoContent = () => {
        switch (activeButton) {
            case 'סקירה כללית':
                return <div>זוהי סקירה כללית</div>;
            case 'סטטוס':
                return <div>זוהי סטטוס</div>;
            case 'בתי ספר':
                return <Schools/>;
            case 'SMS בקרת':
                return <div>זוהי SMS בקרת</div>;
            case 'יומני שגיאות':
                return <div>זוהי יומני שגיאות</div>;
            case 'תגמולים':
                return <div>זוהי תגמולים</div>;
            default:
                return null;
        }
    };

    return(

       <React.Fragment>

            <section className="Main-Head">

                <img src={Logo} alt="Logo"/>

                <p>{dateTime.toLocaleString()}</p>

                <button>להתנתק</button>

            </section>

            <section className="Main-Content">

                <div className="Side-Bar">

                    <button className={activeButton === 'סקירה כללית' ? 'activeButton' : ''} onClick={() => handleButtonClick('סקירה כללית')}>סקירה כללית</button>
                    
                    <button className={activeButton === 'סטטוס' ? 'activeButton' : ''} onClick={() => handleButtonClick('סטטוס')}>סטטוס</button>
                    
                    <button className={activeButton === 'בתי ספר' ? 'activeButton' : ''} onClick={() => handleButtonClick('בתי ספר')}>בתי ספר</button>
                    
                    <button className={activeButton === 'SMS בקרת' ? 'activeButton' : ''} onClick={() => handleButtonClick('SMS בקרת')}>SMS בקרת</button>
                    
                    <button className={activeButton === 'יומני שגיאות' ? 'activeButton' : ''} onClick={() => handleButtonClick('יומני שגיאות')}>(error) יומני שגיאות</button>
                    
                    <button className={activeButton === 'תגמולים' ? 'activeButton' : ''} onClick={() => handleButtonClick('תגמולים')}>תגמולים</button>

                </div>

                <div className="Main-Info">

                    {renderMainInfoContent()}

                </div>

            </section>
            
        </React.Fragment>

    )
}

export default Home