import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from './Firebase';

import '../Styles/Teachers/Tmain.css'

const Teachers = () => {
    const { School_Name } = useParams();
    const [teachers, setTeachers] = useState([]);

    useEffect(() => {
        const fetchTeachers = async () => {
            try {
                const snapshot = await db.collection('Teachers').where('schoolName', '==', School_Name).get();
                const teachersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setTeachers(teachersData);
            } catch (error) {
                console.error('Error fetching teachers:', error);
            }
        };

        fetchTeachers();
    }, [School_Name]);

    return (
        <div className="TMain">
            <h1>Teachers</h1>
            <div className="Tinfo">
                {teachers.map(teacher => (
                    <div className="inTinfo" key={teacher.id}>
                        {teacher.id} - {teacher.roll} - {teacher.email} - {teacher.phoneNumber} - {teacher.lastName} - {teacher.firstName}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Teachers;
