import React, { useState, useEffect } from "react";
import '../Styles/Schools/Schools-Main.css';
import { db } from "./Firebase";
import { Link } from "react-router-dom";

const Schools = () => {
    const [schools, setSchools] = useState([]);
    const [newSchoolName, setNewSchoolName] = useState('');
    const [newSchoolPrincipal, setNewSchoolPrincipal] = useState('');
    const [newSchoolLocation, setNewSchoolLocation] = useState('');
    const [newSchoolType, setNewSchoolType] = useState('');
    const [newPrivateCode, setNewPrivateCode] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchSchoolsFromFirestore();
    }, []);

    const fetchSchoolsFromFirestore = async () => {
        try {
            const snapshot = await db.collection('Schools').get();
            const fetchedSchools = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setSchools(fetchedSchools);
        } catch (error) {
            console.error('Error fetching schools:', error);
        }
    };

    const handleAddSchool = async () => {
        if (newSchoolName.trim() !== '') {
            try {
                await db.collection('Schools').doc(newSchoolName).set({ 
                    School_Name: newSchoolName, 
                    School_Principal: newSchoolPrincipal, 
                    School_Location: newSchoolLocation,
                    School_Type: newSchoolType,
                    Private_Code: newPrivateCode
                });
                setNewSchoolName('');
                setNewSchoolPrincipal('');
                setNewSchoolLocation('');
                setNewSchoolType('');
                setNewPrivateCode('');
                fetchSchoolsFromFirestore();
                setShowModal(false);
            } catch (error) {
                console.error('Error adding school:', error);
            }
        }
    };

    return (
        <React.Fragment>
            <div className="School-Main">
                <div className="Header">
                    <h1>בתי ספר</h1>
                    <button onClick={() => setShowModal(true)}>הוסף בית ספר</button>
                </div>

                {showModal && (
                <div className="modal">
                    <div className="modal-content" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row-reverse', gap: '20px', marginTop: '20px'}}>
                        <span style={{cursor: 'pointer'}} className="close" onClick={() => setShowModal(false)}>&times;</span>
                        <input
                            type="text"
                            value={newSchoolName}
                            onChange={e => setNewSchoolName(e.target.value)}
                            placeholder="שם בית הספר"
                        />

                        <input
                            type="text"
                            value={newSchoolPrincipal}
                            onChange={e => setNewSchoolPrincipal(e.target.value)}
                            placeholder="מנהל בית הספר"
                        />

                        <input
                            type="text"
                            value={newSchoolLocation}
                            onChange={e => setNewSchoolLocation(e.target.value)}
                            placeholder="מיקום בית הספר"
                        />

                        <select
                            value={newSchoolType}
                            onChange={e => setNewSchoolType(e.target.value)}
                            placeholder="סוג בית הספר"
                        >
                            <option value="">בחר סוג בית ספר</option>
                            <option value="יסודי">יסודי</option>
                            <option value="חטיבה">חטיבת ביניים</option>
                            <option value="תיכון">תיכון</option>
                        </select>


                        <input
                            type="text"
                            value={newPrivateCode}
                            onChange={e => setNewPrivateCode(e.target.value)}
                            placeholder="קוד פרטי"
                        />
                        <button onClick={handleAddSchool}>הוסף בית ספר</button>
                    </div>
                </div>
            )}

                <div className="All-Schools">
                    {schools.map(school => (
                        <div className="SInfo" key={school.id}>
                            <p>{school.School_Name}</p>
                            <p>{school.School_Principal}</p>
                            <p>{school.School_Location}</p>
                            <p>{school.School_Type}</p>
                            <p>{school.Private_Code}</p>
                            <Link to={`/School/Info/${school.School_Name}`} className='link'>מידע</Link>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Schools;
