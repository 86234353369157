import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { db } from "./Firebase";

import '../Styles/Schools/School-Info.css'

const SchoolInfo = () => {
    const { School_Name } = useParams();
    const [loading, setLoading] = useState(true);
    const [schoolData, setSchoolData] = useState(null);

    useEffect(() => {

        const fetchSchoolData = async () => {
            try {
                const snapshot = await db.collection('Schools').doc(School_Name).get();
                if (snapshot.exists) {
                    setSchoolData(snapshot.data());
                    setLoading(false);
                } else {
                    console.log('School not found');
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching school data:', error);
                setLoading(false);
            }
        };

        fetchSchoolData();
       
        const fetchData = () => {
            setTimeout(() => {
                setLoading(false); 
            }, 1000);
        };

        fetchData();

        return () => clearTimeout(fetchData);
    }, [School_Name]);

    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <React.Fragment>
                    <div className="SMain-Info">
                        <h1>{School_Name}</h1>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '200px', color: 'rgb(0, 179, 255)', fontSize: '20px' }}>
                            <p>{schoolData ? `מקום: ${schoolData.School_Location}` : 'Location not available'}</p>
                            <p>{schoolData ? `מנהל: ${schoolData.School_Principal}` : 'Principal not available'}</p>
                            <p>{schoolData ? `סוג בית ספר: ${schoolData.School_Type}` : 'School type not available'}</p>
                            <p>{schoolData ? `קוד פרטי: ${schoolData.Private_Code}` : 'Private code not available'}</p>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};    

export default SchoolInfo;
